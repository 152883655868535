var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    _vm._l(_vm.actions, function (action, index) {
      return _c("li", { key: index }, [
        _c(
          "a",
          {
            directives: [
              {
                name: "scroll-to",
                rawName: "v-scroll-to",
                value: {
                  el: `#form-step-${action.menuNumber}-${action.value}`,
                  offset: -88,
                },
                expression:
                  "{ el: `#form-step-${action.menuNumber}-${action.value}`, offset: -88 }",
              },
            ],
            attrs: { href: "#" },
          },
          [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm._f("truncate")(action.msg, 75)) + "\n\t\t"
            ),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }