<template>
	<b-collapse :id="myId">
		<ul class="nav flex-column">
			<li v-for="(action, index) in actions" :key="index" class="nav-item">
				<a class="nav-link" v-scroll-to="{ el: `#form-step-${action.menuNumber}-${action.value}`, offset: -88 }" href="#">
					{{ action.msg }}
				</a>
			</li>
		</ul>
	</b-collapse>
</template>

<script>
import SecondChildAction from './SecondChildAction';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import { getDeepTreeChecked } from '@/cruds/albertactionplan.crud';
Vue.use(VueScrollTo);

export default {
	name: 'FirstChildAction',

	components: {
		SecondChildAction
	},

	props: {
		albertActions: { type: Array, required: true },
		parentId: { type: Number, required: true },
		myId: { type: String, required: true }
	},

	data() {
		return {
			albert_actions: [],
			actions: []
		};
	},

	watch: {
		albertActions(val) {
			this.albert_actions = val;
			this.getAlbertActionsById(this.parentId);
		}
	},

	methods: {
		getAlbertActionsById(id, parent = 0) {
			this.actions = getDeepTreeChecked(this.albert_actions, id, parent);
		}
	}
};
</script>

<style scoped></style>
