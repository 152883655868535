var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "8" } },
                _vm._l(_vm.albert_actions, function (albert_action, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      albert_action.checked
                        ? _c(
                            "b-card",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        id: `form-step-${albert_action.menuNumber}-${albert_action.value}`,
                                        lg: "12",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "clearfix mb-4" },
                                        [
                                          _c(
                                            "h2",
                                            { staticClass: "text-primary h4" },
                                            [_vm._v(_vm._s(albert_action.msg))]
                                          ),
                                        ]
                                      ),
                                      _c("GreenFilmDocs", {
                                        attrs: {
                                          GFId: albert_action.id,
                                          GFType: "albertaction",
                                          GFSubType:
                                            _vm.getAlbertActionValueStr(
                                              albert_action
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "b-col",
                {
                  staticClass: "order-2 d-none d-lg-block",
                  attrs: { cols: "12", md: "4" },
                },
                [
                  _c("div", { staticClass: "sidenav-sticky sticky-top pb-4" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _c("nav", [
                          _c(
                            "ul",
                            { staticClass: "nav flex-column" },
                            _vm._l(_vm.menus, function (menu, index) {
                              return _c(
                                "li",
                                { key: index, staticClass: "nav-item" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName: "v-b-toggle",
                                          value: `collapseNav${index}`,
                                          expression: "`collapseNav${index}`",
                                        },
                                      ],
                                      staticClass: "collapse-btn",
                                      attrs: { variant: "link", block: "" },
                                    },
                                    [
                                      _c("strong", [_vm._v(_vm._s(menu.text))]),
                                      _c("i", {
                                        staticClass: "icon-arrow-down",
                                      }),
                                    ]
                                  ),
                                  _c("FirstChildAction", {
                                    attrs: {
                                      "albert-actions": _vm.albert_actions,
                                      "parent-id": menu.value,
                                      "my-id": `collapseNav${index}`,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }