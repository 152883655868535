<style>
.app-body {
	overflow-x: unset;
}

.header-fixed .app-header {
	z-index: 1021;
}

.sidenav-sticky {
	top: 68px;
}

.btn-link.collapse-btn {
	color: #323635;
	text-align: left;
	padding-left: 0;
	padding-right: 0;
	clear: both;
	transition: all 0.4s;
}

.btn-link.collapse-btn:hover,
.btn-link.collapse-btn:active,
.btn-link.collapse-btn:focus {
	padding-left: 8px;
	background-color: #f3f4f5;
	text-decoration: none;
}

.btn-link.collapse-btn i {
	float: right;
	margin: 6px 12px 0 0;
	font-size: 0.7rem;
}
.scroll-area {
	position: relative;
	margin: auto;
	width: 400px;
	height: 300px;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<div>
			<!-- <b-row>
				<b-col cols="12" md="8">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">{{ FormMSG(1, 'Action Final Report') }}</h1>
				</b-col>
			</b-row> -->
			<b-row>
				<b-col cols="12" md="8">
					<div v-for="(albert_action, index) in albert_actions" :key="index">
						<b-card v-if="albert_action.checked">
							<b-row>
								<b-col :id="`form-step-${albert_action.menuNumber}-${albert_action.value}`" lg="12">
									<div class="clearfix mb-4">
										<!--                     <pre>{{albert_action}}</pre> -->
										<h2 class="text-primary h4">{{ albert_action.msg }}</h2>
									</div>
									<GreenFilmDocs
										:GFId="albert_action.id"
										GFType="albertaction"
										:GFSubType="getAlbertActionValueStr(albert_action)"
									></GreenFilmDocs>
								</b-col>
							</b-row>
						</b-card>
					</div>
				</b-col>
				<b-col cols="12" md="4" class="order-2 d-none d-lg-block">
					<div class="sidenav-sticky sticky-top pb-4">
						<div class="card">
							<div class="card-body">
								<nav>
									<ul class="nav flex-column">
										<li class="nav-item" v-for="(menu, index) in menus" :key="index">
											<b-button variant="link" block class="collapse-btn" v-b-toggle="`collapseNav${index}`">
												<strong>{{ menu.text }}</strong>
												<i class="icon-arrow-down"></i>
											</b-button>
											<FirstChildAction :albert-actions="albert_actions" :parent-id="menu.value" :my-id="`collapseNav${index}`" />
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</errorContainer>
</template>

<script>
// import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import GreenFilmDocs from '@/components/greenfilm/greenfilmdocs';
import FirstChildAction from '@/components/albert/albertprod/albertreport/FirstChildAction';
import languageMessages from '@/mixins/languageMessages';
import { getAlbertActions } from '@/cruds/albertactionplan.crud';

export default {
	name: 'AlbertActionReport',

	components: {
		Loading,
		GreenFilmDocs,
		FirstChildAction
	},
	mixins: [languageMessages],

	// apollo: {
	// 	GF: function () {
	// 		this.reloadData();
	// 	}
	// },

	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			// GF: {},
			warning: '',
			isLoading: false,
			// totalPts: 0,

			albert_actions: []
		};
	},

	computed: {
		menus() {
			return this.FormMenu(1305);
		}
	},

	async mounted() {
		await this.makeAlberActions();
	},

	methods: {
		getAlbertActionValueStr(aa) {
			var valStr = aa.value.toFixed();
			//console.log("in getAlbertActionValueStr:",aa);
			//console.log("in getAlbertActionValueStr valStr:",valStr);
			return valStr;
		},
		// prepareMenu(menuId) {
		// 	let response = this.FormMenu(menuId);
		// 	response.map((r) => {
		// 		r.parent_id = 1310;
		// 	});
		// 	return response;
		// },
		async makeAlberActions() {
			this.albert_actions = await getAlbertActions();
		}
		// computeTotalPoints() {
		// 	this.totalPts = 0;
		// 	if (this.GF.a1ElectricityFromLocalNetwork) {
		// 		this.totalPts += this.GF.a1Value;
		// 	}
		// 	if (this.GF.b1VehiculeEuro5) {
		// 		this.totalPts += this.GF.b1Value;
		// 	}
		// 	//        console.log("this.totalPts:",this.totalPts);
		// 	return this.totalPts;
		// },
		// reloadData() {
		// 	this.isLoading = true;
		// 	this.$apollo
		// 		.query({
		// 			query: query_GF,
		// 			variables: {},
		// 			// options: { fetchPolicy: 'network-only' },
		// 			// options: { fetchPolicy: 'no-cache' },
		// 			// force update from server
		// 			fetchPolicy: 'network-only'
		// 		})
		// 		.then((result) => {
		// 			this.GF = result.data.GetGreenFilm;
		// 			this.computeTotalPoints();
		// 			//console.log("GF in reload:", this.GF);
		// 		})
		// 		.catch((error) => {
		// 			console.log(error);
		// 			this.erreur = error;
		// 		})
		// 		.finally(() => (this.isLoading = false));
		// },
		// saveInformation() {
		// 	//this.isLoading = true;
		// 	var UpdatedGreenFilm = this.GF;
		// 	var GreenFilmId = parseInt(this.GF.id, 10);
		// 	//console.log("UpdatedGreenFilm:", UpdatedGreenFilm);
		// 	this.$apollo
		// 		.mutate({
		// 			mutation: mutation_GFupdate,
		// 			variables: {
		// 				GreenFilmId,
		// 				UpdatedGreenFilm
		// 			}
		// 		})
		// 		.then((result) => {
		// 			this.GF = result.data.UpdGreenFilm;
		// 			// this.computeTotalPoints();
		// 		})
		// 		.catch((error) => {
		// 			console.log(error);
		// 			//this.erreur = error
		// 			var myerrorObject = {
		// 				err: ''
		// 			};
		// 			myerrorObject.err = error.message;
		// 			this.erreur = myerrorObject;
		// 		})
		// 		.finally(() => (this.isLoading = false));
		// },
		// downloadExport() {
		// 	console.log('indownload');

		// 	this.isLoading = true;
		// 	this.warning = '';

		// 	var fromDate = this.from + 'T00:00:00Z';
		// 	var toDate = this.to + 'T00:00:00Z';

		// 	var config = {
		// 		headers: {
		// 			Authorization: 'Bearer ' + store.state.token
		// 		}
		// 	};
		// 	var servicePath = 'GreenFilmFinalReport';
		// 	//console.log("servicePath:",servicePath);
		// 	var fullPath = process.env.VUE_APP_GQL + '/' + servicePath + '/?From=' + fromDate + '&To=' + toDate + '&Email=0';
		// 	console.log('fullPath:', fullPath);

		// 	var axiosTmp = require('axios').create();
		// 	axiosTmp
		// 		.get(fullPath, config)
		// 		.then((response) => {
		// 			if (response.status == 200) {
		// 				var urlStr = process.env.VUE_APP_GQL + '/images/' + response.data;
		// 				console.log('urlStr:', urlStr);
		// 				this.$nextTick(function () {
		// 					console.log('in nexttick url:', urlStr);
		// 					//window.location.href = urlStr;
		// 					window.open(urlStr); // in a new window
		// 				});
		// 			}
		// 		})
		// 		.catch((error) => {
		// 			console.log({
		// 				error
		// 			});
		// 			if (error.message.includes('Network')) {
		// 				this.warning = 'Network error.';
		// 			} else if (error.response) {
		// 				if (error.response.status == 401) {
		// 					this.warning = 'Not connected.';
		// 				} else if (error.response.status == 400) {
		// 					this.warning = 'Invalid format.';
		// 				} else if (error.response.status == 404) {
		// 					this.warning = this.FormMSG(22, 'No Data in the selected period.');
		// 				}
		// 			}
		// 		})
		// 		.finally(() => (this.isLoading = false));
		// }
	}
};
</script>
