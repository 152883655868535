<template>
	<ul>
		<li v-for="(action, index) in actions" :key="index">
			<a v-scroll-to="{ el: `#form-step-${action.menuNumber}-${action.value}`, offset: -88 }" href="#">
				{{ action.msg | truncate(75) }}
			</a>
		</li>
	</ul>
</template>

<script>
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import { getDeepTreeChecked } from '@/cruds/albertactionplan.crud';
Vue.use(VueScrollTo);

export default {
	name: 'SecondChildAction',

	props: {
		albertActions: { type: Array, required: true },
		parentId: { type: Number, required: true },
		myId: { type: String, required: true },
		firstParentId: { type: Number, required: true }
	},

	data() {
		return {
			albert_actions: [],
			actions: []
		};
	},

	mounted() {
		this.getAlbertActionsById(this.firstParentId, this.parentId);
	},

	methods: {
		getAlbertActionsById(id, parent = 0) {
			this.actions = getDeepTreeChecked(this.albertActions, id, parent);
		}
	}
};
</script>

<style scoped>
ul {
	list-style: none;
}
ul > li > a {
	color: #626368;
	text-decoration: none;
	font-weight: 600;
}
ul > li > a:hover {
	color: #353ea1;
}
</style>
