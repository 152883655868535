var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-collapse", { attrs: { id: _vm.myId } }, [
    _c(
      "ul",
      { staticClass: "nav flex-column" },
      _vm._l(_vm.actions, function (action, index) {
        return _c("li", { key: index, staticClass: "nav-item" }, [
          _c(
            "a",
            {
              directives: [
                {
                  name: "scroll-to",
                  rawName: "v-scroll-to",
                  value: {
                    el: `#form-step-${action.menuNumber}-${action.value}`,
                    offset: -88,
                  },
                  expression:
                    "{ el: `#form-step-${action.menuNumber}-${action.value}`, offset: -88 }",
                },
              ],
              staticClass: "nav-link",
              attrs: { href: "#" },
            },
            [_vm._v("\n\t\t\t\t" + _vm._s(action.msg) + "\n\t\t\t")]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }